import * as React from "react"

import { Head } from "@/components/Head"
import { GlobalLayout } from "@/components/GlobalLayout"
import { LivesSection } from "@/components/sections/LivesSection"
import { Pagination } from "@/components/Pagination"
import { PaginationPageContext } from "@/types/PageContext"
import { TitleWithBgImg } from "@/components/TitleWithBgImg"
import { useImage } from "@/hooks/useImage"
import { PageProps } from "gatsby"
import { PageLivesQuery } from "@root/types/graphql-types"

type Props = PageProps<PageLivesQuery, PaginationPageContext>

export const Lives: React.FC<Props> = ({ data, pageContext }) => {
    const { getImageProps, Image } = useImage()
    return (
        <GlobalLayout
            Head={<Head title="lives archive" description="lives Archive" />}
        >
            <LivesSection
                liveArticles={data.allContentfulLives.edges.map((e) => e.node)}
                Title={
                    <TitleWithBgImg
                        title="LIVE INFO"
                        BgImage={
                            <Image
                                {...getImageProps(
                                    data.contentfulSite?.siteHeroImage
                                        ?.gatsbyImageData
                                )}
                            />
                        }
                    />
                }
            >
                <Pagination
                    currentPage={pageContext.currentPage}
                    numPages={pageContext.numberOfPages}
                    basePath="/lives"
                    className="p-section__pagination"
                />
            </LivesSection>
        </GlobalLayout>
    )
}
