import React from 'react'

import { Link } from 'gatsby'

export type Props = {
    ribbon?: {
        color: 'red' | 'blue' | 'yellow'
        text: string
    }
    href: string
    addClass?: string
    Icon?: React.ReactElement | null
    children: React.ReactElement[] | React.ReactElement
}

export const _ArticleCardLayout: React.FC<Props> = ({
    ribbon,
    addClass,
    Icon,
    children,
    href,
}) => {
    return (
        <div className={`p-list-card ${addClass ? addClass : ''}`}>
            {ribbon && (
                <div className={`p-list-card__ribbon--${ribbon.color}`}>
                    {ribbon.text}
                </div>
            )}
            <Link className="p-list-card__link" to={href}>
                <article className="p-list-card__container">
                    <figure className="p-list-card__img c-square-img">
                        {Icon ? Icon : <></>}
                    </figure>

                    <div className="p-list-card__info">{children}</div>
                </article>
            </Link>
        </div>
    )
}
