import * as React from "react"

import {
    _ArticleCardLayout,
    Props as _ArticleCardLayoutProps,
} from "../_ArticleCardLayout"
import { LiveInfoType } from "@/types/LiveInfoType"
import { isLiveFinished } from "@/functions/isLiveFinished"
import { formatDate } from "@/functions/formatDate"

type Props = {
    liveInfo: LiveInfoType
} & Omit<_ArticleCardLayoutProps, "children">

const DEFAULT_DISPLAY_ACTS = 3

export const LivesCard: React.FC<Props> = ({
    liveInfo,
    ribbon,
    addClass,
    Icon,
    href,
}) => {
    const { liveTitle, liveDate, liveActs, livePlaceName, slug } = liveInfo
    const liveDateObj = new Date(liveDate)
    const liveActsLength = liveActs?.length || 0

    return (
        <_ArticleCardLayout {...{ ribbon, addClass, Icon, href }}>
            <time className="p-list-card__date">
                {formatDate(liveDateObj, "/")}
            </time>
            <h2
                className="p-list-card__title"
                style={
                    isLiveFinished(liveDateObj)
                        ? { textDecoration: "line-through" }
                        : {}
                }
            >
                {liveTitle || ""}
            </h2>
            <LiveActs
                liveActs={liveActs || undefined}
                displayActs={
                    liveActsLength >= DEFAULT_DISPLAY_ACTS
                        ? DEFAULT_DISPLAY_ACTS
                        : liveActsLength
                }
                slug={slug || ""}
            />
            <p className="p-list-card__bottom-info">{livePlaceName}</p>
        </_ArticleCardLayout>
    )
}

type LiveActs = {
    liveActs?: LiveInfoType["liveActs"]
    displayActs: number
    slug: string
}

const LiveActs: React.FC<LiveActs> = ({ liveActs, displayActs, slug }) => {
    return (
        <ul className="p-list-card__tags">
            {liveActs ? (
                <>
                    {Array.from({ length: displayActs }, (_, i: number) => (
                        <li
                            className="p-list-card__tag"
                            key={`${slug}-${liveActs[i]}`}
                        >
                            {liveActs[i]}
                            {i + 1 < displayActs && (
                                <span className="p-list-card__tag-delim">
                                    /
                                </span>
                            )}
                        </li>
                    ))}
                    {liveActs.length > DEFAULT_DISPLAY_ACTS && (
                        <>
                            <span className="p-list-card__tag-delim">/</span>
                            <li className="p-list-card__tag">and more...</li>
                        </>
                    )}
                </>
            ) : (
                <li>出演はフライヤーの通り</li>
            )}
        </ul>
    )
}
