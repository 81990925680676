import { formatDate } from '@/functions/formatDate'

export const isLiveFinished = (liveDate: Date): boolean => {
    const today = formatDate(new Date())
    const formatedLiveDate = formatDate(liveDate)

    const todayNum = parseInt(today)
    const liveDateNum = parseInt(formatedLiveDate)

    return todayNum > liveDateNum
}
