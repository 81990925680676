import * as React from 'react'

import {
    Props as _ArticleCardLayoutProps,
    _ArticleCardLayout,
} from '../_ArticleCardLayout'
import { NewsInfoType } from '@/types/NewsInfoType'
import { opAbridgementText } from '@/functions/opAbridgementText'
import { BREAK_POINTS } from '@/constants/BREAK_POINTS'

type Props = {
    newsInfo: NewsInfoType
} & Omit<_ArticleCardLayoutProps, 'children'>

const { useState, useEffect } = React

export const NewsCard: React.FC<Props> = ({
    newsInfo,
    ribbon,
    addClass,
    Icon,
    href,
}) => {
    const { newsTags, newsTitle, updatedAt, slug } = newsInfo
    return (
        <_ArticleCardLayout {...{ ribbon, addClass, Icon, href }}>
            <time
                className="p-list-card__date"
                dateTime={updatedAt.replace('/', '-')}
            >
                {updatedAt}
            </time>
            <h2 className="p-list-card__title">{newsTitle || ''}</h2>
            <ul className="p-list-card__tags">
                {newsTags?.length !== 0 &&
                    newsTags?.map((newsTag) => (
                        <React.Fragment key={`${slug}-${newsTag}`}>
                            <li className="p-list-card__tag">
                                <span className="c-tag">{newsTag}</span>
                                <span className="p-list-card__tag-delim" />
                            </li>
                        </React.Fragment>
                    ))}
            </ul>
            {/* <div>
            </div> */}
        </_ArticleCardLayout>
    )
}
